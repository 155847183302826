import React from 'react';
import { useParams } from 'react-router-dom';
import Retool from 'react-retool';

function InternalTools() {
const { id } = useParams();
  const retoolParams = { accessId: id }

  return (
    <div className="App">
    {/*<div>The URL parameter id is: {id}</div>*/}
    <div className="retool-box" style={{ height: '100vh' }}>
    <Retool
      url="https://evolved.retool.com/embedded/public/77da4743-34d8-44bd-a1c3-9d75168f3b8b"
      data={retoolParams}
    />
    </div>
    </div>
  );
}

export default InternalTools;
