import React from 'react';
import { useParams } from 'react-router-dom';
import Retool from 'react-retool';

function TalentIntake() {
const { id } = useParams();
  const retoolParams = { talentId: id }

  return (
    <div className="App">
    {/*<div>The URL parameter id is: {id}</div>*/}
    <div className="retool-box" style={{ height: '100vh' }}>
    <Retool
      url="https://evolved.retool.com/embedded/public/b6ac4318-5543-497c-863d-d873b6870854"
      data={retoolParams}
    />
    </div>
    </div>
  );
}

export default TalentIntake;
