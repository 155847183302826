import React from 'react';
import { useParams } from 'react-router-dom';

function FrontCallback() {
const { id } = useParams();

  return (
    <div className="App">
    {id}
    </div>
  );
}

export default FrontCallback;
