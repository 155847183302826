import './App.css';
import {useEffect, React} from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import TalentIntake from './components/TalentIntake';
import InternalTools from './components/InternalTools';
import FrontCallback from './components/FrontCallback';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/intake/:id" element={<TalentIntake />} />
        <Route path="/internal/:id" element={<InternalTools />} />
        <Route path="/frontcallback/:id" element={<FrontCallback />} />
        <Route path="/" element={<RedirectEvolved />} />
      </Routes>
    </Router>
  );
}

function RedirectEvolved() {
  useEffect(() => {
    const timeout = setTimeout(() => {
      // 👇️ redirects to an external URL
      window.location.replace('https://evolvedtalent.com');
    }, 100);

    return () => clearTimeout(timeout);
  }, []);
}

export default App;
